import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestros-trabajos',
  templateUrl: './nuestros-trabajos.component.html',
  styleUrls: ['./nuestros-trabajos.component.scss']
})
export class NuestrosTrabajosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { window.scroll(0, 0);
  }

}
