<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Quienes somos</h2>
        <ol>
          <li><a routerLink="/inicio">Inicio</a></li>
          <li>Quienes somos</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->

  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">

      <div class="row content">
        <div class="col-lg-6">
          <h2>Lo que necesites, consultanos.</h2>
          <!-- <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assum perenda sruen jonee trave</h3> -->
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            Somos un equipo dedicado al desarrollo de Sitios Web. ¿Queres empezar tu proyecto, sos un profesional, tenes un negocio y deseas que todos puedan conocerte? Con redes sociales no basta.
          </p>
          <ul>
            <li><i class="ri-check-double-line"></i> Con tu sitio vas a poder hacer que tu marca se luzca!</li>
            <li><i class="ri-check-double-line"></i> Tu diseño original es lo que te va a diferenciar de tus colegas.</li>
            <li><i class="ri-check-double-line"></i> Tu logo, colores, es lo que va a crear tu identidad en la Web!</li>
          </ul>
          <!-- <p class="font-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.
          </p> -->
        </div>
      </div>

    </div>
  </section><!-- End About Section -->

  <!-- ======= Team Section ======= -->
  <section id="team" class="team section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Quienes somos</h2>
        <p>Con quien trabajamos</p>
      </div>

      <div class="row">

        <div class="col-lg-6">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="assets/img/WS.JPG" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h2>Digital Web Innova</h2>
              <span>Diseñador Web</span>
              <p>Nos encargamos del diseño de los sitios web y la implementacion de soluciones informáticas </p><br>
              <div class="social">
                <!-- <a href=""><i class="ri-twitter-fill"></i></a> -->
                <a href="https://www.facebook.com/WebeandoSitio"><i class="ri-facebook-fill"></i></a>
                <!-- <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-4 mt-lg-0">
          <div class="member d-flex align-items-start">
            <!-- <div class="pic"><img src="assets/img/DP.png" class="img-fluid" alt=""></div> -->
            <div class="member-info">
              <h4><img src="assets/img/DP.png" width="200px" height="50px" alt=""></h4>
              <span>Diseñador gráfico</span>
              <p>Estudio de diseño gráfico dedicado a generar logotipos, identidad de marca, edición de fotografias
                y contenido para redes sociales.
              </p>
              <div class="social">
                <!-- <a href=""><i class="ri-twitter-fill"></i></a> -->
                <a href="https://www.facebook.com/disenomaspapell"><i class="ri-facebook-fill"></i></a>
                <a href="https://www.instagram.com/disenomaspapel/"><i class="ri-instagram-fill"></i></a>
                <!-- <a href=""> <i class="ri-linkedin-box-fill"></i> </a> -->
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-6 mt-4">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="assets/img/team/team-3.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>William Anderson</h4>
              <span>CTO</span>
              <p>Quisquam facilis cum velit laborum corrupti</p>
              <div class="social">
                <a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="col-lg-6 mt-4">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="assets/img/team/team-4.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Amanda Jepson</h4>
              <span>Accountant</span>
              <p>Dolorum tempora officiis odit laborum officiis</p>
              <div class="social">
                <a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div> -->

      </div>

    </div>
  </section>
  <!-- End Team Section

   ======= Our Skills Section ======= -->
  <section id="skills" class="skills">
    <div class="container">

      <div class="section-title">
        <h2>Nuestras habilidades</h2>
        <p>Chequeá nuestras habilidades</p>
      </div>

      <div class="row skills-content">

        <div class="col-lg-6">

          <div class="progress">
            <span class="skill">HTML <i class="val">100%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">CSS <i class="val">90%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">JavaScript <i class="val">80%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

        </div>

        <div class="col-lg-6">

          <div class="progress">
            <span class="skill">Typescript <i class="val">80%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

          <!-- <div class="progress">
            <span class="skill">WordPress/CMS <i class="val">90%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
-->
          <div class="progress">
            <span class="skill">Photoshop <i class="val">100%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </section><!-- End Our Skills Section -->

</main><!-- End #main -->
