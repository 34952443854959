import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { NuestrosTrabajosComponent } from './pages/nuestros-trabajos/nuestros-trabajos.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ServicesComponent } from './pages/services/services.component';

const routes: Routes = [
  { path: 'inicio', component: HomeComponent },
  { path: 'nuestros-trabajos', component: NuestrosTrabajosComponent },
  { path: 'nuestros-servicios', component: ServicesComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'quienes-somos', component: AboutComponent },
  { path: 'contacto', component: ContactComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
