<!-- ======= Header ======= -->
<header id="header" class="fixed-top ">
  <div class="container d-flex align-items-center">

    <h1 class="logo"><a routerLink="/inicio">Digital Web Innova</a></h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

    <nav class="nav-menu d-none d-lg-block">

      <ul>
        <li routerLinkActive="active"><a routerLink="/inicio" >Inicio</a></li>
        <li routerLinkActive="active"><a routerLink="/nuestros-trabajos" routerLinkActive="active">Nuestros trabajos</a></li>
        <li routerLinkActive="active"><a routerLink="/nuestros-servicios" routerLinkActive="active">Servicios</a></li>
        <li routerLinkActive="active"><a routerLink="/portfolio" routerLinkActive="active">Portfolio</a></li>
        <li routerLinkActive="active"><a routerLink="/quienes-somos" routerLinkActive="active">Quienes somos</a></li>
        <li routerLinkActive="active"><a routerLink="/contacto" routerLinkActive="active">Contacto</a></li>

      </ul>

    </nav><!-- .nav-menu -->

    <a routerLink="/contacto" class="get-started-btn ml-auto">Contactanos</a>

  </div>
</header><!-- End Header -->
