import { Component, OnInit } from '@angular/core';
import * as $ from "jQuery";
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    (function ($) {
      $(document).ready(function () {
        if (!!window['SHIPPING_SETTINGS'] && !!window['SHIPPING_SETTINGS']['alexis'])
          window['SHIPPING_SETTINGS']['alexis']();
      });

    })
      (jQuery);
    window.scroll(0, 0);
  }

}
