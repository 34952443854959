 <!-- ======= Footer ======= -->
 <footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 col-md-6">
          <div class="footer-info">
            <h3>Digital Web Innova</h3>
            <p>
             Villa María, Córdoba <br>
              <br><br>
              <strong>Teléfono:</strong> +5411 3793 9664<br>
              <strong>Email:</strong> info@digitalwebinnova.com<br>
            </p>
            <div class="social-links mt-3">
              <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->
              <a href="https://www.facebook.com/WebeandoSitio" class="facebook"><i class="bx bxl-facebook"></i></a>
              <!-- <a href="https://www.instagram.com/webeando_sitio/" class="instagram"><i class="bx bxl-instagram"></i></a> -->
              <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
              <a href="https://walink.co/303735" class="whatsapp"><i class="bx bxl-whatsapp"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 footer-links">
          <h4>Nuestras secciones</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/inicio">Inicio</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/nuestros-trabajos">Nuestros trabajos</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/nuestros-servicios">Nuestros servicios</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/portfolio">Portfolio</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/quienes-somos">Quienes somos</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/contacto">Contacto</a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 footer-links">
          <h4>Nuestros servicios</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/nuestros-servicios">Diseño sitios Web</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/nuestros-servicios">Diseño sitios web dinámicos</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/nuestros-servicios">Diseño e implementacion de E-commerce</a></li>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li> -->

          </ul>
        </div>


      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>Sailor</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/sailor-free-bootstrap-theme/ -->
      Designed by <a>Digital Web Innova</a>
    </div>
  </div>
</footer><!-- End Footer -->
