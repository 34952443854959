<section id="hero">
  <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

    <ol class="carousel-indicators"></ol>

    <div class="carousel-inner" role="listbox">

      <!-- Slide 1 -->
      <div class="carousel-item active" style="background-image: url(assets/img/slide/slide-1.jpg)">
        <div class="carousel-container">
          <div class="container">
            <h2 class="animate__animated animate__fadeInDown">Bienvenido a<span> Digital Web Innova</span></h2>
            <p class="animate__animated animate__fadeInUp">Diseñamos sitios Web, implementamos soluciones informáticas a tus necesidades</p>
            <a routerLink="/quienes-somos" class="btn-get-started animate__animated animate__fadeInUp scrollto">Mas info</a>
          </div>
        </div>
      </div>


    </div>
<!--
    <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>

    <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> -->

  </div>
</section><!-- End Hero -->

<main id="main">

  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">

      <div class="row content">
        <div class="col-lg-6">
          <h2>Seguí nuestros consejos</h2>
          <!-- <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assum perenda sruen jonee trave</h3> -->
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            Somos un equipo dedicado al desarrollo de Sitios Web. ¿Queres empezar tu proyecto, sos un profesional, tenes un negocio y deseas que todos puedan conocerte? Con redes sociales no basta.
          </p>
          <ul>
            <li><i class="ri-check-double-line"></i> Con tu sitio vas a poder hacer que tu marca se luzca!</li>
            <li><i class="ri-check-double-line"></i> Tu diseño original es lo que te va a diferenciar de tus colegas.</li>
            <li><i class="ri-check-double-line"></i> Tu logo, colores, es lo que va a crear tu identidad en la Web!</li>
          </ul>
          <!-- <p class="font-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.
          </p> -->
        </div>
      </div>

    </div>
  </section><!-- End About Section -->

  <!-- ======= Clients Section ======= -->
  <!-- <section id="clients" class="clients section-bg">
    <div class="container">

      <div class="row">

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-1.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-2.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-3.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-4.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-5.png" class="img-fluid" alt="">
        </div>

        <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <img src="assets/img/clients/client-6.png" class="img-fluid" alt="">
        </div>

      </div>

    </div>
  </section> -->
  <!-- End Clients Section -->

  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">

      <div class="section-title">
        <h2>Servicios</h2>
        <p>Los servicios que ofrecemos</p>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="icon-box">
            <i class="icofont-computer"></i>
            <h4><a href="#">Diseñamos sitios web</a></h4>
            <p>Vos tenes una idea, un proyecto, tenes tu propia marca y queres mostrar tus productos consultanos y te cotizamos.
              </p>
              <p>
                ¡Excelentes precios! ¡sin sorpresas! Atención personalizada. <br><br><br><br><br><br><br>
              </p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-chart-bar-graph"></i>
            <h4><a href="#">Diseñamos sitios web dinámicos</a></h4>
            <p>¿Tenes una idea o proyecto y queres exponer tus productos, novedades, noticias? Nosotros diseñamos tu sitio Web y te
              brindamos un administrador desde el cual vas a poder cargar todos tus productos fácilmente! Vas a poder hacerlo desde una
              computadora, tablet o desde un celular.
              Podrá tener un buscador interno
              de categorías o por item.  </p>
              <p>*Administrador 100% personalizado*</p>
              <p>¡Excelentes precios! ¡Soporte via WhatsApp!</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-image"></i>
            <h4><a href="#">E-Commerce</a></h4>
            <p>¿Tenes un proyecto, productos que mostrar y venderlos Online? Nosotros diseñamos tu e-commerce con toda la información de tu sitio Web</p>
              <p>
                Usamos la robustez y seguridad de Mercado Pago. Tus clientes podrán acceder a todos los medios de pago (tarjeta de crédito, débito y transferencia)
                sin necesidad de tener cuenta en Mercado Pago.
              </p>
              <p>
                *Administrador 100% personalizado*
              </p>
              <!-- <p>La robustez y seguridad de Mercado pago. Tus clientes podrán acceder a todos los medios de pago de Mercado pago sin necesidad de tener cuenta.</p> -->
              <p>¡Excelentes precios! ¡Soporte via WhatsApp!</p>

          </div>
        </div>
        <!-- <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-settings"></i>
            <h4><a href="#">Nemo Enim</a></h4>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-earth"></i>
            <h4><a href="#">Magni Dolore</a></h4>
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-tasks-alt"></i>
            <h4><a href="#">Eiusmod Tempor</a></h4>
            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
          </div>
        </div> -->
      </div>

    </div>
  </section><!-- End Services Section -->

  <!-- ======= Portfolio Section ======= -->


</main><!-- End #main -->
