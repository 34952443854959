<p>nuestros-trabajos works!</p>
<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Nuestros trabajos</h2>
        <ol>
          <li><a routerLink="/inicio">Inicio</a></li>
          <li>Nuestros trabajos</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->

  <!-- ======= Blog Section ======= -->
  <section id="blog" class="blog">
    <div class="container">

      <div class="row">

        <div class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/capturasWeb/MaryKayZonaNorte.png" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a href="https://marykayzonanorte.com/">Mary Kay Zona Norte</a>
            </h2>

            <!-- <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
              </ul>
            </div> -->

            <div class="entry-content">
              <p>
                Sitio de productos de cosmética de Zona Norte. Consta de:
              </p>
              <p>Un buscador.</p>
              <p>Comunicación directa con el vendedor via WhatsApp.</p>
              <p>Detalles de producto.</p>
              <p>*La carga de productos y categorías se realiza mediante un administrador web 100% personalizado.*</p>

              <div class="read-more">
                <a href="https://marykayzonanorte.com/">Ir al sitio</a>
              </div>
            </div>

          </article><!-- End blog entry -->
        </div>

        <div class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/capturasWeb/RepuestosRolon.png" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a href="http://www.repuestosrolon.com/"> <br><br> Repuestos Rolon</a>
            </h2>

            <!-- <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
              </ul>
            </div> -->

            <div class="entry-content">
              <p>
                Sitio web de empresa dedicada a la venta de repuestos del automotor.
              </p>
              <p>
                La comunicación se realiza via formulario de contacto y via teléfonica con un botón.
              </p>
              <br><br><br><br><br><br>
              <div class="read-more">
                <a href="http://www.repuestosrolon.com/">Ir al sitio</a>
              </div>
            </div>

          </article><!-- End blog entry -->
        </div>

        <div class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/capturasWeb/OrdasiSrl.png" alt="" class="img-fluid">
            </div>
<br><br>
            <h2 class="entry-title">
              <a href="https://ordasisrl.com/">Ordasi SRL</a>
            </h2>

            <!-- <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
              </ul>
            </div> -->

            <div class="entry-content">
              <p>
                Sitio web de empresa dedicada a la minería.
              </p>
              <p>La comunicación se realiza vía WhatsApp mediante un botón, formulario de contacto y a través de e-mail</p>
              <br><br><br><br><br><br>
              <div class="read-more">
                <a href="https://ordasisrl.com/">Ir al sitio</a>
              </div>
            </div>

          </article><!-- End blog entry -->
        </div>

        <div class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/capturasWeb/QueRicoRicardoRojas.png" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a href="https://quericoricardorojas.com/">Qué rico Ricardo Rojas</a>
            </h2>

            <!-- <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
              </ul>
            </div> -->

            <div class="entry-content">
              <p>
                Sitio web dedicado a la venta de comidas vía delivery.
              </p>
              <p>
                La comunicación se realiza principalmente vía WhatsApp mediante un botón. Al hacer click sobre un producto se precarga en el msj el nombre del producto y así enviar por WhatsApp
              </p>
              <p>
                Detalles de producto.
              </p>
              <p>
                Buscador de comidas y de vendedores. También incluye un buscador de comidas dentro del perfil de cada vendedor.
              </p>
              <div class="read-more">
                <a href="https://quericoricardorojas.com/">Ir al sitio</a>
              </div>
            </div>

          </article><!-- End blog entry -->
        </div>

        <div class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/capturasWeb/TransporteNortruck.png" alt="" class="img-fluid">
            </div>
<br><br>
            <h2 class="entry-title">
              <a href="https://transportenortruck.com/">Transporte Nortruck</a>
            </h2>

            <!-- <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
              </ul>
            </div> -->

            <div class="entry-content">
              <p>
                Sitio web de empresa dedicada al servicio de transporte de cargas.
              </p>
              <p>
                La comunicación se realiza mediante WhatsApp, formulario de contacto y vía e-mail
              </p>
              <br><br><br><br><br><br><br>
              <div class="read-more">
                <a href="https://transportenortruck.com/">Ir al sitio</a>
              </div>
            </div>

          </article><!-- End blog entry -->
        </div>

        <div class="col-lg-4  col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry">

            <div class="entry-img">
              <img src="assets/img/capturasWeb/Ecommerce.PNG" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a href="https://ecommerce.webeandositio.com/">WS E-commerce</a>
            </h2>

            <!-- <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="blog-single.html">John Doe</a></li>
                <li class="d-flex align-items-center"><i class="icofont-wall-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
              </ul>
            </div> -->

            <div class="entry-content">
              <p>
               E-commerce para la venta de productos online.
              </p>
              <p>
                La comunicación se realiza via WhatsApp, via e-mail y vía formulario de contacto
              </p>
              <p>
                La venta se realiza usando la robustez y seguridad de la plataforma de Mercado Pago, accediendo de esa manera a todos los medios de pago.
              </p>
              <p>
                Detalles de producto
              </p>
              <p>
                La carga de productos y categorías se realiza desde un administrador Web 100% personalizado.
              </p>
              <div class="read-more">
                <a href="https://ecommerce.webeandositio.com/">Ir al sitio</a>
              </div>
            </div>

          </article><!-- End blog entry -->
        </div>

      </div>

      <!-- <div class="blog-pagination" data-aos="fade-up">
        <ul class="justify-content-center">
          <li class="disabled"><i class="icofont-rounded-left"></i></li>
          <li><a href="#">1</a></li>
          <li class="active"><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li><a href="#"><i class="icofont-rounded-right"></i></a></li>
        </ul>
      </div> -->

    </div>
  </section><!-- End Blog Section -->

</main><!-- End #main -->
