import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form: FormGroup;

  constructor(public fb: FormBuilder,
    private http: HttpClient) {
    this.form = this.fb.group({
      name: [''],
      phone: [''],
      subject: [''],
      senderEmail: [''],
      toEmail: ['alexisfserrano07@gmail.com'],
      body: [''],
    })

  }
  ngOnInit() { window.scroll(0, 0);}

  submitForm() {
    var formData: any = new FormData();
    formData.append("name", this.form.get('name').value);
    formData.append("phone", this.form.get('phone').value);
    formData.append("subject", this.form.get('subject').value);
    formData.append("toEmail", this.form.get('toEmail').value);
    formData.append("senderEmail", this.form.get('senderEmail').value);
    formData.append("body", this.form.get('body').value);
    this.http.post(environment.emailUrl, formData).subscribe((r: any) => {
      Swal.fire(
        '',
        'El mensaje fue enviado correctamente!',
        'success'
      )
      this.reset();
    });
  }
  reset(){
    this.form = this.fb.group({
      name: [''],
      phone: [''],
      subject: [''],
      senderEmail: [''],
      toEmail: ['alexisfserrano07@gmail.com'],
      body: [''],
    })
  }
}




