<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Contacto</h2>
        <ol>
          <li><a routerLink="/inicio">Inicio</a></li>
          <li>Contacto</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">

      <div>
        <iframe style="border:0; width: 100%; height: 270px;"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Villa%20Maria,%20Cordoba+(Mi%20nombre%20de%20egocios)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          frameborder="0" allowfullscreen></iframe>
      </div>

      <div class="row mt-5">

        <div class="col-lg-4">
          <div class="info">
            <div class="address">
              <i class="icofont-google-map"></i>
              <h4>Ubicación:</h4>
              <p>Villa María, Córdoba</p>
            </div>

            <div class="email">
              <i class="icofont-envelope"></i>
              <h4>Email:</h4>
              <p>info@digitalwebinnova.com</p>
            </div>

            <div class="phone">
              <i class="bx bxl-whatsapp"></i>
              <h4>WhatsApp:</h4>
              <p><a href="https://walink.co/303735">+541137939664</a> </p>
            </div>

          </div>

        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">

          <form novalidate [formGroup]="form" method="post" (ngSubmit)="submitForm()" class="php-email-form">
            <div class="form-row">
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" formControlName="name" type="text" name="name" placeholder="Tu nombre"
                  data-rule="minlen:4" data-msg="Please enter at least 4 chars" />

              </div>
              <div class="col-md-6 form-group">
                <input type="email" class="form-control" formControlName="senderEmail" name="senderEmail" id="email" placeholder="Tu e-mail"
                  data-rule="email" data-msg="Please enter a valid email" />

              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" formControlName="subject" name="subject" placeholder="Asunto"
                  data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />

              </div>
              <div class="col-md-6 form-group">
                <input type="tel" class="form-control" formControlName="phone" name="phone" placeholder="telefono"
                  data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
              </div>
            </div>
            <div class="form-group">
              <textarea class="form-control"formControlName="body" name="body" rows="5" data-rule="required"
                data-msg="Por favor escribe algo" placeholder="Escriba su mensaje"></textarea>

            </div>
            <div class="text-center"><button type="submit">Enviar mensaje</button></div>
          </form>

        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->

</main><!-- End #main -->
