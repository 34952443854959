<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Portolio</h2>
        <ol>
          <li><a routerLink="/inicio">Inicio</a></li>
          <li>Portolio</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->

  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container">

      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">Todo</li>
            <li data-filter=".filter-app">Web estática</li>
            <li data-filter=".filter-web">Web dinámica</li>
            <!-- <li >Web</li> -->
          </ul>
        </div>
      </div>

      <div class="row portfolio-container">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/TransporteNortruck.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Transporte Nortruck</h4>
              <!-- <p>Transporte Nortruck</p> -->
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/TransporteNortruck.PNG" data-gall="portfolioGallery" class="venobox" title="App 1"><i class="bx bx-expand"></i></a>
                <a href="http://transportenortruck.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas1.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Qué rico Ricardo Rojas</h4>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas1.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="http://quericoricardorojas.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Mary Kay Zona Norte</h4>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://marykayzonanorte.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/TransporteNortruck1.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Transporte Nortruck</h4>
              <p>formulario contacto</p>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-3.jpg" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-expand"></i></a>
                <a href="http://transportenortruck.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte1.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Mary Kay Zona Norte</h4>
              <p>Buscador de productos</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte1.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://marykayzonanorte.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/RolonRepuestos.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Rolon Repuestos</h4>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/RolonRepuestos.PNG" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-expand"></i></a>
                <a href="http://www.repuestosrolon.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte2.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Mary Kay Zona Norte</h4>
              <p>Detalle de producto</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte2.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://marykayzonanorte.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/Ordasi.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Ordasi SRL</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/Ordasi.PNG" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-expand"></i></a>
                <a href="https://ordasisrl.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte3.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Mary Kay Zona Norte</h4>
              <p>Categorías de productos</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/MaryKayZonaNorte3.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://marykayzonanorte.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/Ordasi2.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Ordasi SRL</h4>
              <p>Formulario de contacto</p>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/Ordasi2.PNG" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-expand"></i></a>
                <a href="https://ordasisrl.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Que rico Ricardo Rojas</h4>
              <p>Buscador de vendedores</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="http://quericoricardorojas.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/RolonRepuestos.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Rolon Repuestos</h4>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/RolonRepuestos.PNG" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-expand"></i></a>
                <a href="http://www.repuestosrolon.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas2.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Que rico Ricardo Rojas</h4>
              <p>Envío por WhatsApp</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas2.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="http://quericoricardorojas.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/RolonRepuestos1.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Rolon Repuestos</h4>
              <p>Galería de imágenes</p>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/RolonRepuestos1.PNG" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-expand"></i></a>
                <a href="http://www.repuestosrolon.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/E-Commerce.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>WS E-commerce</h4>
              <p>Principal</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/E-Commerce.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://ecommerce.webeandositio.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/E-Commerce1.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>WS E-commerce</h4>
              <p>Detalle de producto</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/E-Commerce1.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://ecommerce.webeandositio.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/E-Commerce2.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>WS E-commerce</h4>
              <p>Categoría</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/E-Commerce2.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://ecommerce.webeandositio.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/E-Commerce3.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>WS E-commerce</h4>
              <p>Método de pago</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/E-Commerce3.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://ecommerce.webeandositio.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/E-Commerce4.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>WS E-commerce</h4>
              <p>Contacto</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/E-Commerce4.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="https://ecommerce.webeandositio.com/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas2.PNG" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Que rico Ricardo Rojas</h4>
              <p>Envío por WhatsApp</p>
              <div class="portfolio-links">
                <a href="assets/img/capturasWeb/capturasdesitio/QueRicoRicardoRojas2.PNG" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-expand"></i></a>
                <a href="http://quericoricardorojas.com/#/" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>







        <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 2</h4>
              <p>Card</p>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" class="venobox" title="Card 2"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div> -->




        <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 1</h4>
              <p>Card</p>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-7.jpg" data-gall="portfolioGallery" class="venobox" title="Card 1"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 3</h4>
              <p>Card</p>
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-8.jpg" data-gall="portfolioGallery" class="venobox" title="Card 3"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" data-gall="portfolioDetailsGallery" data-vbtype="iframe" class="venobox" title="Portfolio Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>
        </div> -->


      </div>

    </div>
  </section><!-- End Portfolio Section -->

</main><!-- End #main -->
