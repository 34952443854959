<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Nuestros servicios</h2>
        <ol>
          <li><a routerLink="/inicio">Inicio</a></li>
          <li>Nuestros servicios</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->

  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">

      <div class="row">
        <div class="col-md-6">
          <div class="icon-box">
            <i class="icofont-computer"></i>
            <h4><a href="#">Diseñamos sitios web</a></h4>
            <p>Vos tenes una idea, un proyecto, tenes tu propia marca y queres mostrar tus productos consultanos y te cotizamos.
              </p>
              <p>
                ¡Excelentes precios! ¡sin sorpresas! Atención personalizada. <br><br><br><br><br><br><br>
              </p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-chart-bar-graph"></i>
            <h4><a href="#">Diseñamos sitios web dinámicos</a></h4>
            <p>¿Tenes una idea o proyecto y queres exponer tus productos, novedades, noticias? Nosotros diseñamos tu sitio Web y te
              brindamos un administrador desde el cual vas a poder cargar todos tus productos facilmente! Vas a poder hacerlo desde una
              computadora, tablet o desde un celular.
              Podrá tener un buscador interno
              de categorías o por item.  </p>
              <p>*Administrador 100% personalizado*</p>
              <p>¡Excelentes precios! ¡Soporte via WhatsApp!</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-image"></i>
            <h4><a href="#">E-Commerce</a></h4>
            <p>¿Tenes un proyecto, productos que mostrar y venderlos Online? Nosotros diseñamos tu e-commerce con toda la información de tu sitio Web</p>
              <p>
                Usamos la robustez y seguridad de Mercado Pago. Tus clientes podrán acceder a todos los medios de pago (tarjeta de crédito, débito y transferencia)
                sin necesidad de tener cuenta en Mercado Pago.
              </p>
              <p>
                *Administrador 100% personalizado*
              </p>
              <p>La robustez y seguridad de Mercado pago. Tus clientes podrán acceder a todos los medios de pago de Mercado pago sin necesidad de tener cuenta.</p>
              <p>¡Excelentes precios! ¡Soporte via WhatsApp!</p>

          </div>
        </div>
        <!-- <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-settings"></i>
            <h4><a href="#">Nemo Enim</a></h4>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-earth"></i>
            <h4><a href="#">Magni Dolore</a></h4>
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="icofont-tasks-alt"></i>
            <h4><a href="#">Eiusmod Tempor</a></h4>
            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
          </div>
        </div> -->
      </div>

    </div>
  </section><!-- End Services Section -->

  <!-- ======= Features Section ======= -->
  <section id="features" class="features">
    <div class="container">

      <div class="section-title">
        <h2>Características</h2>
        <p>Qué hacemos</p>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <ul class="nav nav-tabs flex-column">
            <li class="nav-item">
              <a class="nav-link active show" data-toggle="tab" href="#tab-1">Con Diseño</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#tab-2">Sin Diseño</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#tab-3">Pariatur explicabo vel</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#tab-4">Nostrum qui quasi</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#tab-5">Iusto ut expedita aut</a>
            </li> -->
          </ul>
        </div>
        <div class="col-lg-9 mt-4 mt-lg-0">
          <div class="tab-content">
            <div class="tab-pane active show" id="tab-1">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Paso a paso si tenes tus logos</h3>
                  <p>1) Nos contactas (por WhatsApp o a través de nuestro formulario de contacto), nos contas de qué se trata tu sitio</p>
                  <p>2) Te mostramos varios diseños de sitios, elegis los colores tu marca</p>
                  <p>3) Te cotizamos</p>
                  <p>4) Te hacemos una carga del sitio, nos mostras donde queres cada contenido</p>
                  <p>5) Establecemos una fecha de entrega</p>
                  <p>6) ¡Entregamos!</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/img/features-2.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="tab-pane" id="tab-2">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Paso a paso si no tenes tus logos</h3>
                  <p class="font-italic">Si no tenes tus logos podes elegir esta opción. <strong> Diseño + Papel </strong>se encargará de la creación de logotipos, tu identidad de marca y la edición de fotografías.</p>
                  <p>1) Nos contactas (por WhatsApp o a través de nuestro formulario de contacto), nos contas de qué se trata tu proyecto, nos mostras tus ideas para el logo y los colores de tu marca.</p>
                  <p>2) Diseñamos tu logotipo, corregimos tus imágenes y que generen así un impacto visual.</p>
                  <p>3) Te mostramos varios diseños de sitios, elegis los colores que quieras aplicar de tu marca.</p>
                  <p>3) Te cotizamos</p>
                  <p>4) Te hacemos una carga del sitio, nos mostras donde queres cada contenido</p>
                  <p>5) Establecemos una fecha de entrega</p>
                  <p>6) ¡Entregamos!</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/img/features-4.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane" id="tab-3">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Impedit facilis occaecati odio neque aperiam sit</h3>
                  <p class="font-italic">Eos voluptatibus quo. Odio similique illum id quidem non enim fuga. Qui natus non sunt dicta dolor et. In asperiores velit quaerat perferendis aut</p>
                  <p>Iure officiis odit rerum. Harum sequi eum illum corrupti culpa veritatis quisquam. Neque necessitatibus illo rerum eum ut. Commodi ipsam minima molestiae sed laboriosam a iste odio. Earum odit nesciunt fugiat sit ullam. Soluta et harum voluptatem optio quae</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/img/features-3.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="tab-pane" id="tab-4">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Fuga dolores inventore laboriosam ut est accusamus laboriosam dolore</h3>
                  <p class="font-italic">Totam aperiam accusamus. Repellat consequuntur iure voluptas iure porro quis delectus</p>
                  <p>Eaque consequuntur consequuntur libero expedita in voluptas. Nostrum ipsam necessitatibus aliquam fugiat debitis quis velit. Eum ex maxime error in consequatur corporis atque. Eligendi asperiores sed qui veritatis aperiam quia a laborum inventore</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/img/features-4.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="tab-pane" id="tab-5">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Est eveniet ipsam sindera pad rone matrelat sando reda</h3>
                  <p class="font-italic">Omnis blanditiis saepe eos autem qui sunt debitis porro quia.</p>
                  <p>Exercitationem nostrum omnis. Ut reiciendis repudiandae minus. Omnis recusandae ut non quam ut quod eius qui. Ipsum quia odit vero atque qui quibusdam amet. Occaecati sed est sint aut vitae molestiae voluptate vel</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/img/features-5.png" alt="" class="img-fluid">
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Features Section -->

</main><!-- End #main -->
